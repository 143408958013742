import { capitalizeFirstLetter, formatDateInTimeZone, handleCodeableConcept } from '../helpers'
import { Period } from '../../types/FHIRTypes/Period'
import { CarePlanBundle } from '../../types/FHIRTypes/Bundle'
import { CarePlan, Activity } from '../../types/FHIRTypes/CarePlan'

export function buildCarePlanActivityOutcome(resource: CarePlan): string {
  const activityOutcome = resource.activity?.map((activity) => activity.outcomeCodeableConcept?.map((outcome) => handleCodeableConcept(outcome)).join(', ')) || 'N/A'

  return activityOutcome[activityOutcome.length - 1] || activityOutcome[0] || 'N/A'
}

export function buildCarePlanStatus(resource: CarePlan): string {
  const carePlanStatus = resource.status ? capitalizeFirstLetter(resource.status) : 'N/A'
  return carePlanStatus
}

export function buildCarePlanPeriod(resource: CarePlan): string {
  const start = resource.period?.start ? formatDateInTimeZone(resource.period.start, 'MM/dd/yyyy', 'UTC') : 'N/A'
  const end = resource.period?.end ? formatDateInTimeZone(resource.period.end, 'MM/dd/yyyy', 'UTC') : 'N/A'
  const period = `${start} - ${end}`
  return period
}

export const formatCarePlanDataForTable = (carePlans: CarePlanBundle): { carePlanId: string; status: string; period: string; }[] => {
  if (!carePlans) return []
  const patientCarePlans = carePlans.entry?.map((carePlan) => carePlan.resource) || []

  return patientCarePlans.map((patientCarePlan) => ({
    carePlanId: patientCarePlan.id,
    status: buildCarePlanStatus(patientCarePlan),
    period: buildCarePlanPeriod(patientCarePlan),
  }))
}
