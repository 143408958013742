import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatCarePlanDataForTable } from '../../../../utils/fhirUtils/carePlan'
import PaginationButtons from '../../../PaginationButtons'
import { CarePlanBundle } from '../../../../types/FHIRTypes/Bundle'

interface Props {
  carePlanData: CarePlanBundle;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}

const CarePlanList: FC<Props> = ({ carePlanData, handleChangePage, isFetching }: Props) => {
  const patientCarePlans = formatCarePlanDataForTable(carePlanData)

  const nextLink = carePlanData && carePlanData.link.find((l: any) => l.relation === 'next')
  const previousLink = carePlanData && carePlanData.link.find((l: any) => l.relation === 'previous')

  return (
    <>
      <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
        {patientCarePlans.length > 0 &&
          <div className='mb-2'>
            <PaginationButtons
              backDisabled={!previousLink}
              nextDisabled={!nextLink}
              onBackClick={() => previousLink && handleChangePage(previousLink.url)}
              onNextClick={() => nextLink && handleChangePage(nextLink.url)}
            />
          </div>
        }
        {carePlanData?.total && `${carePlanData.total.toLocaleString()} record(s) found`}
      </div>
      <Table size='sm' hover>
        <thead>
          <tr>
            <th className='d-table-cell'>Care Plan Id</th>
            <th className='d-table-cell'>Status</th>
            <th className='d-table-cell'>Period</th>
            <th className='d-none d-lg-table-cell'>View Detail</th>
          </tr>
        </thead>
        <tbody>
          {
            isFetching ? (
              <tr>
                <td colSpan={6} rowSpan={15} className='d-flex'>
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                  <span style={{ marginLeft: '10px' }}>Loading Care Plan Data...</span>
                </td>
              </tr>
            ) : (
              patientCarePlans.map((carePlan) => (
                <tr key={carePlan.carePlanId}>
                  <td>{carePlan.carePlanId}</td>
                  <td>{carePlan.status}</td>
                  <td>{carePlan.period}</td>
                  <td className='d-none d-lg-table-cell'><Link to={`/patient/CarePlans/${carePlan.carePlanId}`}>View Detail</Link></td>
                </tr>
              ))
            )            
          }
        </tbody>
      </Table>
    </>
  )
}

export default CarePlanList