import React, { useEffect, useState } from 'react'
import { ConditionEntry, EncounterEntry, LocationEntry, PractitionerEntry } from '../../../../types/FHIRTypes/Entry'
import { Encounter } from '../../../../types/FHIRTypes/Encounter'
import { useHistory } from 'react-router-dom'
import { Col, Row, Spinner } from 'react-bootstrap'
import { handleCodeableConcept, formatDateInTimeZone, buildHumanName, formatDateWithFormat } from '../../../../utils/helpers'
import { Reference } from '../../../../types/FHIRTypes/Reference'

interface EncounterDetailProps {
    encounters: EncounterEntry[];
    encounterDetailId: string | undefined;
    conditions: ConditionEntry[];
    practitioners: PractitionerEntry[];
    locations: LocationEntry[];
    isFetching: boolean;
    dataExists: boolean;
}

const EncounterDetail: React.FC<EncounterDetailProps> = ({ encounters, encounterDetailId, conditions, practitioners, locations, isFetching, dataExists }) => {
    const [encounter, setEncounter] = useState<Encounter | null>(null)

    const history = useHistory()

    useEffect(() => {
        if (dataExists) {
            const foundEncounter = encounters.find((e) => e.resource.id === encounterDetailId)

            if (foundEncounter) {
                setEncounter(foundEncounter.resource)
            } else {
                history.push('/404')
            }
        }
    }, [dataExists])

    const getEncounterType = () => {
        return encounter?.type?.map((type) => handleCodeableConcept(type)).join(', ')
    }

    const getParticipantIndividual = (individual: Reference | undefined) => {
        if (!individual || !individual.reference) return ''

        const referenceId = individual.reference.split('/')[1]

        const practitioner = practitioners.find((p) => p.resource.id === referenceId)

        if (!practitioner) return ''

        return buildHumanName(practitioner.resource.name)
    }

    const getEncounterDiagnosis = (diagnosis: Encounter['diagnosis']) => {
        if (!diagnosis) return []

        const diagnosisList = diagnosis.map((d) => {
            const condition = conditions.find((c) => d.condition?.reference && c.resource.id === d.condition.reference.split('/')[1])
            return condition?.resource
        })
        return diagnosisList
    }

    const getEncounterLocations = (location: Encounter['location']) => {
        if (!location) return []

        const locationList = location.map((l) => {
            const location = locations.find((loc) => l.location?.reference && loc.resource.id === l.location.reference.split('/')[1])
            return location?.resource
        })

        return locationList
    }

    return (
        <dl className='dataContainer'>
            {
                isFetching ? (
                    <>
                        <Spinner
                            as='span'
                            animation='border'
                            role='status'
                            aria-hidden='true'
                        />
                        Loading Member Encounters
                    </>
                ) : (
                    <Row>
                        <Col sm={3}>
                            <dt>Reason</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>{encounter ? encounter.reasonCode && handleCodeableConcept(encounter.reasonCode[0]) : ''}</dd>
                        </Col>
                        <Col sm={3}>
                            <dt>Status</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>{encounter?.status || ''}</dd>
                        </Col>
                        <Col sm={3}>
                            <dt>Period</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>{encounter?.period ? `${encounter.period.start ? `Start: ${formatDateInTimeZone(encounter.period.start, 'MM/dd/yyyy', 'UTC')}` : ''} ${encounter.period.end ? `End: ${formatDateInTimeZone(encounter.period.end, 'MM/dd/yyyy', 'UTC')}` : ''}` : ''}</dd>
                        </Col>
                        <Col sm={3}>
                            <dt>Class</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>{encounter?.class?.display || ''}</dd>
                        </Col>
                        <Col sm={3}>
                            <dt>Type</dt>
                        </Col>
                        <Col sm={9}>
                            <dd>{getEncounterType()}</dd>

                        </Col>
                        <Col sm={3}>
                            <dt>Involved Participants</dt>
                        </Col>
                        <Col sm={9}>
                            {
                                encounter?.participant?.map((participant, index) => (
                                    <Row className='border rounded m-2' key={`encounter-participant-${index}`}>
                                        <Col sm={3}>
                                            <dt>Type</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>{participant.type?.map((type) => handleCodeableConcept(type)).join(', ')}</dd>
                                        </Col>
                                        <Col sm={3}>
                                            <dt>Period</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>{participant.period?.start ? formatDateInTimeZone(participant.period.start, 'MM/dd/yyyy', 'UTC') : ''} - {participant.period?.end ? formatDateInTimeZone(participant.period.end, 'MM/dd/yyyy', 'UTC') : ''}</dd>
                                        </Col>
                                        <Col sm={3}>
                                            <dt>Individual</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>{getParticipantIndividual(participant.individual)}</dd>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Col>
                        <Col sm={3}>
                            <dt>Diagnosis</dt>
                        </Col>
                        <Col sm={9}>
                            {
                                getEncounterDiagnosis(encounter?.diagnosis).map((condition) => (
                                    <Row className='border rounded m-2' key={`encounter-diagnosis-${condition?.id}`}>
                                        <Col sm={3}>
                                            <dt>Code</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>{condition?.code ? handleCodeableConcept(condition?.code) : ''}</dd>
                                        </Col>
                                        <Col sm={3}>
                                            <dt>Date Recorded</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>{condition?.recordedDate ? formatDateWithFormat(condition.recordedDate, 'MM/dd/yyyy') : ''}</dd>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Col>
                        <Col sm={3}>
                            <dt>Location</dt>
                        </Col>
                        <Col sm={9}>
                            {
                                getEncounterLocations(encounter?.location).map((location) => (
                                    <Row className='border rounded m-2' key={`encounter-location-${location?.id}`}>
                                        <Col sm={3}>
                                            <dt>Name</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>{location?.name || ''}</dd>
                                        </Col>
                                        <Col sm={3}>
                                            <dt>Address</dt>
                                        </Col>
                                        <Col sm={9}>
                                            <dd>{location?.address?.line?.join(', ') || ''}</dd>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Col>
                    </Row>
                )
            }
        </dl>
    )
}

export default EncounterDetail