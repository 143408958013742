import React from 'react'
import { EncounterEntry } from '../../../../types/FHIRTypes/Entry'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Encounter } from '../../../../types/FHIRTypes/Encounter'
import { formatDateInTimeZone, handleCodeableConcept } from '../../../../utils/helpers'
import { EncounterBundle } from '../../../../types/FHIRTypes/Bundle'
import PaginationButtons from '../../../PaginationButtons'

interface EncounterListProps {
    data: EncounterBundle | undefined;
    encounters: EncounterEntry[];
    isFetching: boolean;
    handleChangePage: (url: string) => void;
}

const EncounterList: React.FC<EncounterListProps> = ({ encounters, isFetching, data, handleChangePage }) => {

    const getEncounterReason = (encounter: Encounter) => {
        if (encounter.reasonCode?.[0]?.coding?.[0]?.display) {
            return handleCodeableConcept(encounter.reasonCode[0])
        }

        // Return a more meaningful default value
        return 'Reason not specified'
    }

    const nextLink = data && data.link.find((l: any) => l.relation === 'next')
    const previousLink = data && data.link.find((l: any) => l.relation === 'previous')

    return (
        <>
            {
                data && data.total > 0 && (
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {data.total > 0 &&
                            <div className='mb-2'>
                                <PaginationButtons
                                    backDisabled={!previousLink}
                                    nextDisabled={!nextLink}
                                    onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                                    onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                                />
                            </div>
                        }
                        {`${data.total.toLocaleString()} record(s) found`}
                    </div>
                )
            }
            <Table size='sm' hover>
                <thead>
                    <tr>
                        <th className='d-table-cell'>Reason</th>
                        <th className='d-none d-lg-table-cell'>Status</th>
                        <th className='d-none d-lg-table-cell'>Period</th>
                        <th className='d-none d-lg-table-cell'>View Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isFetching ? (
                            <tr>
                                <td colSpan={4} rowSpan={15} className='d-flex'>
                                    <Spinner
                                        as='span'
                                        animation='border'
                                        role='status'
                                        aria-hidden='true'
                                    />
                                    Loading Member Encounters...
                                </td>
                            </tr>
                        ) : data && data.total === 0 ? (
                            <tr>
                                <td colSpan={4} rowSpan={15} className='text-center'>
                                    No Encounters found for this member.
                                </td>
                            </tr>
                        ) : (
                            <>
                                {
                                    encounters.map((encounter) => (
                                        <tr key={encounter.resource.id}>
                                            <td className='d-table-cell'>
                                                <Link to={`/patient/Encounters/${encounter.resource.id}`}>
                                                    {getEncounterReason(encounter.resource)}
                                                </Link>
                                            </td>
                                            <td className='d-none d-lg-table-cell'>{encounter.resource.status}</td>
                                            <td className='d-none d-lg-table-cell'>
                                                {encounter.resource.period?.start ? formatDateInTimeZone(encounter.resource.period.start, 'MM/dd/yyyy', 'UTC') : ''} - {encounter.resource.period?.end ? formatDateInTimeZone(encounter.resource.period.end, 'MM/dd/yyyy', 'UTC') : ''}
                                            </td>
                                            <td className='d-none d-lg-table-cell'>
                                                <Link to={`/patient/Encounters/${encounter.resource.id}`}>
                                                    View Detail
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </>
                        )
                    }
                </tbody>
                <tfoot>
                    {data && data.total > 0 &&
                        <tr>
                            <td scope="row" colSpan={5}>
                                <PaginationButtons
                                    backDisabled={!previousLink}
                                    nextDisabled={!nextLink}
                                    onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                                    onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                                />
                            </td>
                        </tr>
                    }
                </tfoot>
            </Table >
        </>
    )
}

export default EncounterList