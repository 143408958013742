export enum TimingCodeEnum {
  BID = 'Two times a day at institution specified time',
  TID = 'Three times a day at institution specified time',
  QID = 'Four times a day at institution specified time',
  AM = 'Every morning at institution specified times',
  PM = 'Every afternoon at institution specified times',
  QD = 'Every Day at institution specified times',
  QOD = 'Every Other Day at institution specified times',
  Q1H = 'Every hour at institution specified times',
  Q2H = 'Every 2 hours at institution specified times',
  Q3H = 'Every 3 hours at institution specified times',
  Q4H = 'Every 4 hours at institution specified times',
  Q6H = 'Every 6 Hours at institution specified times',
  Q8H = 'Every 8 hours at institution specified times',
  BED = 'At bedtime (institution specified time)',
  WK = 'Weekly at institution specified time',
  MO = 'Monthly at institution specified time',
}