export enum EventTimingEnum {
  MORN = 'morning',
  MORN_EARLY = 'early morning',
  MORN_LATE = 'late morning',
  NOON = 'noon',
  AFT = 'afternoon',
  AFT_EARLY = 'early afternoon',
  AFT_LATE = 'late afternoon',
  EVE = 'evening',
  EVE_EARLY = 'early evening',
  EVE_LATE = 'late evening',
  NIGHT = 'night',
  PHS = 'after sleep',
  HS = 'before sleep',
  WAKE = 'upon waking',
  C = 'at a meal',
  CM = 'at breakfast',
  CD = 'at lunch',
  CV = 'at dinner',
  AC = 'before a meal',
  ACM = 'before breakfast',
  ACD = 'before lunch',
  ACV = 'before dinner',
  PC = 'after a meal',
  PCM = 'after breakfast',
  PCD = 'after lunch',
  PCV = 'after dinner',
}