import React, { FC, useEffect } from 'react'
import { CarePlanBundle } from '../../../../types/FHIRTypes/Bundle'
import { Row, Col, Spinner, Card } from 'react-bootstrap'
import { buildCarePlanPeriod } from '../../../../utils/fhirUtils/carePlan'
import { handleCodeableConcept } from '../../../../utils/helpers'
import CarePlanActivity from '../CarePlanActivity'

interface Props {
  carePlanDetailId: string;
  carePlanData: CarePlanBundle;
  isFetching: boolean;
}

const CarePlanDetail: FC<Props> = ({ carePlanDetailId, carePlanData, isFetching }) => {
  const carePlanEntry = carePlanData?.entry?.find((carePlan: any) => carePlan.resource.id === carePlanDetailId)
  const carePlanResource = carePlanEntry?.resource

  return (
    <>
      {
        isFetching && (
          <div className='d-flex dataContainer'>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
            <span style={{ marginLeft: '10px' }}>Loading Care Plan Data...</span>
          </div>
        )
      }
      <dl className='dataContainer'>
        {
          carePlanResource ? (
            <Row>
              <Col sm={3}>
                <dt>Care Plan ID</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.id}</dd>
              </Col>
              <Col sm={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>Summary</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <dd dangerouslySetInnerHTML={{ __html: carePlanResource.text?.div || '' }} />
                    <dd>Status: {carePlanResource.text?.status}</dd>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={3}>
                <dt>Based On</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.basedOn?.map((based) => based.display).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Replaces</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.replaces?.map((replaces) => replaces.display).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Part Of</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.partOf?.map((partOf) => partOf.display).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Status</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.status}</dd>
              </Col>
              <Col sm={3}>
                <dt>Intent</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.intent}</dd>
              </Col>
              <Col sm={3}>
                <dt>Category</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.category ? handleCodeableConcept(carePlanResource.category) : ''}</dd>
              </Col>
              <Col sm={3}>
                <dt>Title</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.title}</dd>
              </Col>
              <Col sm={3}>
                <dt>Description</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.description}</dd>
              </Col>
              <Col sm={3}>
                  <dt>Subject</dt>
              </Col>
              <Col sm={9}>
                  <dd>{carePlanResource.subject.display}</dd>
              </Col>
              <Col sm={3}>
                  <dt>Encounter</dt>
              </Col>
              <Col sm={9}>
                  <dd>{carePlanResource.encounter?.display}</dd>
              </Col>
              <Col sm={3}>
                <dt>Period</dt>
              </Col>
              <Col sm={9}>
                <dd>{buildCarePlanPeriod(carePlanResource)}</dd>
              </Col>
              <Col sm={3}>
                <dt>Created Date</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.created}</dd>
              </Col>
              <Col sm={3}>
                <dt>Author</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.author?.display}</dd>
              </Col>
              <Col sm={3}>
                <dt>Contributor</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.contributor?.map((contributor) => contributor.display).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Care Team</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.careTeam?.map((careTeam) => careTeam.display).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Addresses</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.addresses?.map((address) => address.display).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Supporting Info</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.supportingInfo?.map((supportingInfo) => supportingInfo.display).join(', ')}</dd>
              </Col>
              <Col sm={3}>
                <dt>Goal</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.goal?.map((goal) => goal.display).join(', ')}</dd>
              </Col>
              <Col sm={12}>
                <CarePlanActivity patientCarePlan={carePlanResource} />
              </Col>
              <Col sm={3}>
                <dt>Note</dt>
              </Col>
              <Col sm={9}>
                <dd>{carePlanResource.note?.map((note) => note.text).join(', ')}</dd>
              </Col>
            </Row>
          ) : (
            <div className='text-center'>
              No Care Plan found for this member.
            </div>
          )
        }
      </dl>
    </>
  )
}

export default CarePlanDetail