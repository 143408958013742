import humanizeString from 'humanize-string'
import { formatDateInTimeZone } from '../helpers'
import { TimingCodeEnum } from '../../types/TimingCodeEnum'
import { UnitsOfTimeEnum } from '../../types/UnitsOfTimeEnum'
import { EventTimingEnum } from '../../types/EventTimingEnum'

const formatBounds = (repeat: any) => {
  const boundsDuration = repeat.boundsDuration ? `${repeat.boundsDuration.value} ${repeat.boundsDuration.unit}` : ''
  const boundsRange = repeat.boundsRange ? `${repeat.boundsRange.low.value} ${getUnitsOfTimeDescription(repeat.boundsRange.low.unit)} - ${repeat.boundsRange.high.value} ${getUnitsOfTimeDescription(repeat.boundsRange.high.unit)}` : ''
  const boundsPeriod = repeat.boundsPeriod ? `${formatDateInTimeZone(repeat.boundsPeriod.start, 'MM/dd/yyyy', 'UTC')} - ${repeat.boundsPeriod.end ? formatDateInTimeZone(repeat.boundsPeriod.end, 'MM/dd/yyyy', 'UTC') : ''}` : ''
  return boundsDuration ? boundsRange ? `${boundsDuration} (${boundsRange})` : boundsPeriod ? `${boundsDuration} (${boundsPeriod})` : boundsDuration : boundsRange ? boundsPeriod ? `${boundsRange} (${boundsPeriod})` : boundsRange : boundsPeriod ? boundsPeriod : ''
}

const formatCount = (repeat: any) =>
  repeat.count ? `${repeat.count} times${repeat.countMax ? ` to ${repeat.countMax} times` : ''}` : ''

const formatDuration = (repeat: any) =>
  repeat.duration ? `${repeat.duration} ${repeat.durationMax ? `to ${repeat.durationMax} ` : ''}times${repeat.durationUnit ? ` per ${getUnitsOfTimeDescription(repeat.durationUnit)}` : ''}` : ''

const formatFrequency = (repeat: any) =>
  repeat.frequency ? `${repeat.frequency} ${repeat.frequencyMax ? `to ${repeat.frequencyMax} ` : ''}times` : ''

const formatPeriod = (repeat: any) =>
  repeat.period ? `${repeat.period} ${repeat.periodMax ? `to ${repeat.periodMax} ` : ''}times${repeat.periodUnit ? ` per ${getUnitsOfTimeDescription(repeat.periodUnit)}` : ''}` : ''


const formatWhen = (repeat: any) => {
  const offset = repeat.offset ? ` ${repeat.offset} minutes ` : ''
  const when = repeat.when ? `${repeat.when.map((code: string) => getEventTimingDescription(code)).join(', ')}` : ''
  return when ? when.split(',').map((w: string) => `${offset}${w}`).join(', ') : ''  
}

const getEventTimingDescription = (code: string) => {
  const foundCode = EventTimingEnum[code as keyof typeof EventTimingEnum]
  return foundCode || humanizeString(code)
}

const getTimingDescription = (code: string) => {
  const foundCode = TimingCodeEnum[code as keyof typeof TimingCodeEnum]
  return foundCode || humanizeString(code)
}

const getUnitsOfTimeDescription = (code: string) => {
  const foundCode = UnitsOfTimeEnum[code as keyof typeof UnitsOfTimeEnum]
  return foundCode || humanizeString(code)
}

export const getScheduledTimeData = (scheduledTiming: any) => {
  if (scheduledTiming) {
    if (scheduledTiming.event && scheduledTiming.event.length > 0) {
      const formattedEvents = scheduledTiming.event.map((event: any) =>
        formatDateInTimeZone(event, 'MM/dd/yyyy HH:mm z', 'UTC')
      )
      return { type: 'event', data: formattedEvents.join(', ') }
    }

    if (scheduledTiming.repeat) {
      const repeat = scheduledTiming.repeat
      const boundsDescription = formatBounds(repeat)
      const countDescription = formatCount(repeat)
      const durationDescription = formatDuration(repeat)
      const frequencyDescription = formatFrequency(repeat)
      const periodDescription = formatPeriod(repeat)
      const dayOfWeek = repeat.dayOfWeek?.map((day: string) => humanizeString(day)).join(', ')
      const timeOfDay = repeat.timeOfDay?.join(', ')
      const whenDescription = formatWhen(repeat)

      return {
        type: 'repeat',
        data: {
          boundsDescription,
          countDescription,
          durationDescription,
          frequencyDescription,
          periodDescription,
          dayOfWeek,
          timeOfDay,
          whenDescription,
        },
      }
    }

    if (scheduledTiming.code) {
      const { text, coding } = scheduledTiming.code
      if (text) {
        return { type: 'code', data: text }
      }
      const code = coding?.[0]?.code
      return { type: 'code', data: code ? getTimingDescription(code as string) : '' }
    }
  }

  return { type: 'none', data: '' }
}